import React, {Suspense, lazy} from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";

// Lazy loading components and pages
const Home = lazy(() => import("./pages/home/Home"));
const NotFound = lazy(() => import("./pages/notFound/NotFound"));
const Navbar = lazy(() => import("./components/navbar/Navbar"));
const Categories = lazy(() => import("./components/categories/Categories"));
const Footer = lazy(() => import("./components/Footer"));
const ComingSoon = lazy(() => import("./pages/comingSoon/ComingSoon"));

const App = () => {
    return(
        <React.Fragment>
            <BrowserRouter>
                <Suspense fallback={<div>Loading...</div>}>
                    <Navbar />
                    <Categories />
                    <Routes>
                        <Route index element={<Home />}/>
                        <Route path='/comingSoon' element={<ComingSoon />}/>
                        <Route path='*' element={<NotFound />}/>
                    </Routes>
                    <Footer />
                </Suspense>
            </BrowserRouter>
        </React.Fragment>
    );
}

export default App;
